import { propertyEventTracker } from '@propertypal/shared/src/reducers/properties';
import { useAppDispatch } from '@propertypal/shared/src/reducers/store';
import { trackGaEvent } from '@propertypal/shared/src/services/analytics';
import axios from '@propertypal/shared/src/services/axios';
import { Agent } from '@propertypal/shared/src/types/agents';
import { Contact, Property } from '@propertypal/shared/src/types/property';
import CallAgentModal from '@propertypal/web-ui/src/modal/CallAgentModal';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import config from '../../../../app.config.json';

const useCallAgent = () => {
  const [modal, setModal] = useState<React.ReactElement | undefined>();
  const dispatch = useDispatch();
  const asyncDispatch = useAppDispatch();

  const trackCall = (property: Property, accountNumberSelected: string) => {
    asyncDispatch(
      propertyEventTracker(property.id, property.type === 'development', {
        eventType: 'PHONE_REVEAL',
        accountNumber: accountNumberSelected,
      }),
    );
  };

  const trackAgentCall = async (agent: Agent, page: string) => {
    try {
      await axios(
        {
          method: 'POST',
          url: `${config.apiUrl}/account/${agent.accountNumber}/event`,
          data: {
            eventType: 'PHONE_REVEAL',
            eventPageType: page === 'Find an Agent' ? 'FIND_AN_AGENT' : 'AGENT_LANDING',
          },
        },
        dispatch,
      );
    } catch {
      // silent error
    }
  };

  // Used on mobile where calls can be made directly via phone using window.open
  const callAgent = async (property?: Property, contact?: Contact, agent?: Agent, page?: string, location?: string) => {
    trackGaEvent('phone_reveal', {
      page,
      location,
      account_number: agent?.accountNumber || contact?.accountNumber || property?.account.accountNumber,
    });

    if (agent) {
      setModal(undefined);
      if (property) trackCall(property, agent?.accountNumber);
      if (agent.officePhone?.displayNumber) window.open(`tel:${agent.officePhone.displayNumber}`, '_self');
    } else if (contact?.phoneNumber) {
      setModal(undefined);
      if (property) trackCall(property, contact.accountNumber);
      window.open(`tel:${contact.phoneNumber.displayNumber}`, '_self');
    } else if (property) {
      const { contacts } = property;

      if (contacts) {
        if (contacts?.all.length > 1) {
          const onSelected = (organisation: string) => {
            // find account number
            const account = contacts.all.find((a) => a.organisation === organisation);

            if (account) {
              trackCall(property, account.accountNumber);
              // open telephone link
              window.open(`tel:${account?.phoneNumber?.displayNumber}`, '_self');
            }
          };

          setModal(
            <CallAgentModal show onClose={() => setModal(undefined)} contacts={contacts.all} onSelected={onSelected} />,
          );
        } else {
          setModal(undefined);
          const a = contacts.default || contacts.all[0];

          if (a.phoneNumber) {
            trackCall(property, a.accountNumber);
            window.open(`tel:${a.phoneNumber.displayNumber}`, '_self');
          }
        }
      }
    }
  };

  // Used on desktop where phone number is displayed on screen
  const showPhoneModal = (property?: Property, contact?: Contact, agent?: Agent, page?: string, location?: string) => {
    trackGaEvent('phone_reveal', {
      location,
      page,
      account_number: agent?.accountNumber || contact?.accountNumber || property?.account.accountNumber,
    });

    if (agent) {
      if (page) trackAgentCall(agent, page);

      setModal(<CallAgentModal show onClose={() => setModal(undefined)} contacts={[agent]} allowDevelopers />);
    } else if (contact?.phoneNumber) {
      if (property) trackCall(property, contact.accountNumber);

      setModal(<CallAgentModal show onClose={() => setModal(undefined)} contacts={[contact]} allowDevelopers />);
    } else if (property?.contacts) {
      setModal(<CallAgentModal show onClose={() => setModal(undefined)} contacts={property.contacts.all} />);
    }
  };

  return {
    callAgent,
    modal,
    trackCall,
    showPhoneModal,
  };
};

export default useCallAgent;
