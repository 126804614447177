import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const IconContainer = styled.div`
  padding: 5px 5px 5px 0;
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  max-width: 21px;
`;

export const NotificationText = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.primary};
  display: inline-flex;
  vertical-align: middle;
  width: 100%;
  max-width: calc(100% - 30px);
`;
