import { useEffect, useState } from 'react';
import axios, { API_URL } from '../services/axios';

type Return<T> = [
  T | undefined,
  any | undefined,
  boolean,
  (url?: string) => Promise<void>,
  (data: T | undefined) => void,
  Response['headers'] | undefined,
];

const fetchData = async (url: string) => {
  const result = await axios(
    {
      method: 'GET',
      url: `${API_URL}${url}`,
    },
    undefined,
  );

  return result;
};

const useApi = <T = any>(
  url: string,
  loadOnMount: boolean = true,
  initialState: T | undefined = undefined,
): Return<T> => {
  const [loading, setLoading] = useState(loadOnMount);
  const [data, setData] = useState<T | undefined>(initialState);
  const [error, setError] = useState<any>();
  const [headers, setHeaders] = useState<Response['headers'] | undefined>();

  const refetch = async (fetchUrl?: string) => {
    setLoading(true);

    try {
      const result = await fetchData(fetchUrl || url);

      setHeaders(result.headers);
      setData(result.data);
      setLoading(false);
    } catch (err: any) {
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadOnMount) {
      refetch();
    }
  }, []);

  return [data, error, loading, refetch, setData, headers];
};

export default useApi;
