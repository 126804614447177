import { mobileXXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  min-height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 15px;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const Content = styled.div<{ maxWidth?: number }>`
  position: relative;
  max-width: ${(props) => props.maxWidth || 1024}px;
  width: 100%;
  padding: 30px;
  border: solid 1px #d8d8d8;
  background-color: rgba(255, 255, 255, 0.8);

  @media (${mobileXXLargeMax}) {
    padding: 60px 15px 30px;
  }
`;
