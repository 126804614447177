/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { LoaderProps, LoaderText } from './Loader.style';

interface LoadingTextProps extends LoaderProps {
  textLength?: number;
}

const LoadingText: React.FC<LoadingTextProps> = ({ textLength = 10, ...props }) => {
  const getTextLength = () =>
    Array.from({ length: textLength }).map((_, index) => <Fragment key={index}>&emsp; </Fragment>);
  return (
    <LoaderText data-testid="loading-text" lineHeight="1" {...props}>
      {getTextLength()}
    </LoaderText>
  );
};

export default LoadingText;
