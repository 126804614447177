import { mobileXLargeMax } from '@propertypal/shared/src/constants/mediaQueries';
import styled, { css } from 'styled-components';

const AVA_SIZE = 35;
const AVA_MR = 15;

const infoCalc = (size: string) => `calc(${size} + ${AVA_SIZE}px + ${AVA_MR}px)`;

const Container = css`
  padding: 30px;
  display: flex;
  @media screen and (max-width: 1024px) {
    padding: 20px 15px;
  }
`;

export const NotesInfoContainer = styled.div`
  ${Container};
  padding-left: ${infoCalc('30px')};
  padding-bottom: 0px;

  @media screen and (max-width: 1024px) {
    padding-left: ${infoCalc('15px')};
    padding-bottom: 0px;
  }
`;

export const NotesInfo = styled.p`
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.textDark};
`;

export const NoteItemContainer = styled.div`
  ${Container};
  border-bottom: 1px dashed ${({ theme }) => theme.lightGrey};
  overflow: hidden;

  &:first-child {
    padding-top: 15px;
  }

  &:last-child {
    border-bottom-width: 0px;
  }
`;

export const NoteItemContent = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% - ${AVA_SIZE}px - 15px);
`;

export const NoteItemDate = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.29;
  color: ${({ theme }) => theme.backgroundMid};
  text-transform: uppercase;
`;

export const NoteItemTime = styled.span`
  font-size: 16px;
  color: ${({ theme }) => theme.primary};
  line-height: 1.5;
`;

export const NoteItemDetail = styled.p`
  font-weight: 400;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  font-style: italic;
  line-height: 1.5;
  white-space: pre-line;
  word-break: break-word;
  color: ${({ theme }) => theme.textDark};
`;

export const NoteItemFormWrapper = styled.div`
  margin-top: 5px;
`;

export const NoteItemFormNotification = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-basis: 100%;

  @media screen and (${mobileXLargeMax}) {
    flex-direction: column;
  }
`;
