import { Logo } from '@propertypal/shared/src/types/property';
import React from 'react';
import {
  CallAgentModalContainer,
  CallAgentModalImage,
  CallAgentModalOption,
  ContactNumber,
  ContactText,
  CallAgentContactDetails,
} from './CallAgentModal.style';
import WhiteModal from './WhiteModal';

export interface ContactType {
  logo?: Logo;
  logos?: Logo;
  organisation: string;
  developer?: boolean;
  officePhone?: {
    displayNumber: string;
  };
  phoneNumber?: {
    displayNumber: string;
  };
}

interface CallAgentModalProps {
  onClose: () => void;
  show: boolean;
  contacts: ContactType[];
  onSelected?: (organisation: string) => void;
  allowDevelopers?: boolean;
}

const CallAgentModal: React.FC<CallAgentModalProps> = ({ onClose, show, contacts, onSelected, allowDevelopers }) => {
  const items = contacts.filter(
    (c) => (c.phoneNumber?.displayNumber || c.officePhone?.displayNumber) && (!c.developer || allowDevelopers),
  );

  return (
    <WhiteModal ariaLabel="Call an agent" show={show} onClose={onClose} maxWidth={670}>
      <CallAgentModalContainer>
        {items &&
          items.map((item) => {
            const logo = item.logos || item.logo;
            const phoneNumber = item.phoneNumber?.displayNumber || item.officePhone?.displayNumber || '';

            return (
              <CallAgentModalOption key={item.organisation} data-testid="agent-contact-item">
                {logo && (
                  <CallAgentModalImage src={logo.standard} alt={item.organisation} data-testid="agent-contact-logo" />
                )}
                <CallAgentContactDetails>
                  <ContactText data-testid="agent-contact-text">Call {item.organisation}</ContactText>
                  <ContactNumber
                    data-testid="agent-contact-number"
                    href={`tel:${phoneNumber.replace(/\s/g, '')}`}
                    onClick={() => onSelected && onSelected(item.organisation)}
                  >
                    {phoneNumber}
                  </ContactNumber>
                </CallAgentContactDetails>
              </CallAgentModalOption>
            );
          })}
      </CallAgentModalContainer>
    </WhiteModal>
  );
};

export default CallAgentModal;
