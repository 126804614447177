import useDebounce from '@propertypal/shared/src/hooks/useDebounce';
import { deleteNote, saveNote } from '@propertypal/shared/src/reducers/notes';
import { useAppDispatch } from '@propertypal/shared/src/reducers/store';
import { parseApiDate } from '@propertypal/shared/src/services/dayjs';
import { Note } from '@propertypal/shared/src/types/notes';
import Loader from '@propertypal/web-ui/src/animation/Loader.style';
import LoadingText from '@propertypal/web-ui/src/animation/LoadingText';
import TextArea from '@propertypal/web-ui/src/inputs/TextArea';
import MiniIconNotification from '@propertypal/web-ui/src/notifications/MiniIconNotification';
import ProfileAvatar from '@propertypal/web-ui/src/profile/ProfileAvatar';
import React, { useState } from 'react';
import { format } from 'timeago.js';
import {
  NoteItemContainer,
  NoteItemContent,
  NoteItemDate,
  NoteItemTime,
  NoteItemFormWrapper,
  NoteItemDetail,
  NoteItemFormNotification,
} from './Notes.style';

interface NoteItemProps {
  note?: Note;
  hardDelete?: boolean;
  isLoading?: boolean;
  notificationText?: string;
  setNotificationText?: React.Dispatch<React.SetStateAction<string | undefined>>;
  updating?: boolean;
  setUpdating?: React.Dispatch<React.SetStateAction<boolean>>;
  testID?: string;
  onNoteUpdate?: () => void;
}

// 4:3 Ratio, img will be no larger than 340px
const IMAGE_LOCATOR = '340x255:FILL_CROP';
const AUTO_SAVE_DELAY = process?.env?.JEST_WORKER_ID ? 0 : 2000;

const NoteItem: React.FC<NoteItemProps> = ({
  note,
  hardDelete = false,
  isLoading,
  notificationText,
  setNotificationText,
  updating,
  setUpdating,
  testID,
  onNoteUpdate,
}) => {
  const dispatch = useAppDispatch();
  const name = `${note?.firstName || ''} ${note?.surname || ''}`;
  const [noteText, setNoteText] = useState(note?.content || '');

  const getDate = () => {
    if (isLoading) return <LoadingText data-testid="note-date-loading" textLength={5} display="inline-flex" />;
    if (note?.timeModified) return parseApiDate(note?.timeModified).format('DD MMM YYYY');
    return null;
  };

  const getTime = () => {
    if (isLoading) return <LoadingText data-testid="note-time-loading" textLength={9} display="inline-flex" />;
    if (note?.timeModified)
      return (
        <>
          {format(note?.timeModified, 'en_GB')} <strong>{note?.selfAuthored ? 'you' : name}</strong> wrote:
        </>
      );
    return null;
  };

  const handleNoteUpdate = async (value: string) => {
    if (setUpdating) setUpdating(true);

    if (note?.id) {
      if (!value || value?.length === 0) {
        const del = await dispatch(deleteNote(note?.id, !!hardDelete));

        if (del && setNotificationText) setNotificationText('Removed!');
      } else {
        const save = await dispatch(saveNote(note?.id, value, IMAGE_LOCATOR));

        if (setNotificationText && save)
          setNotificationText(!note?.content || note?.content.length === 0 ? 'Saved!' : 'Updated!');
      }

      if (setUpdating) setUpdating(false);
      if (onNoteUpdate) onNoteUpdate();
    }
  };

  const debounceHandler = useDebounce(handleNoteUpdate, AUTO_SAVE_DELAY, { leading: false, trailing: true }, []);

  return (
    <NoteItemContainer data-testid={testID || 'note-item'}>
      <ProfileAvatar
        testID={`note-avatar${isLoading ? '-loading' : ''}`}
        alt={`${name} Avatar`}
        src={note?.authorAvatar}
        isLoading={isLoading}
        mr={15}
        mt={23}
        boxShadow
      />
      <NoteItemContent>
        <NoteItemDate data-testid="note-date">{getDate()}</NoteItemDate>
        <NoteItemTime data-testid="note-time">{getTime()}</NoteItemTime>

        {isLoading && (
          <NoteItemFormWrapper data-testid="note-item-loading">
            <Loader w="100%" h="160px" display="block" />
          </NoteItemFormWrapper>
        )}

        {!isLoading && note?.selfAuthored && (
          <NoteItemFormWrapper>
            <TextArea
              placeholder="Make a note about this property"
              name="note"
              value={noteText}
              rows={6}
              onValueChange={(value) => {
                setNoteText(value);
                debounceHandler(value);
              }}
              testID="pp-text-area"
            />

            {setNotificationText && (
              <NoteItemFormNotification data-testid="note-form-notfication">
                <MiniIconNotification
                  text={notificationText}
                  setNotificationText={setNotificationText}
                  loading={!!updating}
                />
              </NoteItemFormNotification>
            )}
          </NoteItemFormWrapper>
        )}

        {!isLoading && !note?.selfAuthored && (
          <NoteItemDetail data-testid="note-detail">{note?.content}</NoteItemDetail>
        )}
      </NoteItemContent>
    </NoteItemContainer>
  );
};

export default NoteItem;
