import { mobileXXLargeMax, mobileXXLargeMin } from '@propertypal/shared/src/constants/mediaQueries';
import styled from 'styled-components';
import { LinkText } from '../typography';

const mobileHeight = `max-height: 850px`;

export const CallAgentModalContainer = styled.div`
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: 550px;
  margin: 30px auto;

  @media screen and (${mobileXXLargeMax}), (${mobileHeight}) {
    margin: 0 auto;
  }
`;

export const CallAgentModalImage = styled.img`
  width: 100%;
  max-width: 130px;
  margin: 16px auto;
  display: flex;

  @media screen and (${mobileXXLargeMin}) {
    flex-basis: 130px;
    margin-right: 20px;
  }
`;

export const CallAgentModalOption = styled.div`
  color: ${({ theme }) => theme.textDark};
  font-size: 16px;
  margin: 15px 0px;
  text-align: center;
  display: flex;
  align-items: center;

  @media screen and (${mobileXXLargeMax}) {
    flex-direction: column;
  }
`;

export const ContactText = styled.span`
  font-size: 18px;
  line-height: 1.25;
  text-align: left;

  @media screen and (${mobileXXLargeMax}) {
    text-align: center;
  }
`;

export const ContactNumber = styled(LinkText)`
  margin-top: 10px;
  font-size: 22px;
  font-weight: bold;
  display: block;
  color: ${(props) => props.theme.textDark};
  text-decoration: none;
  text-align: left;

  @media screen and (${mobileXXLargeMax}) {
    text-align: center;
  }
`;

export const CallAgentContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: calc(100% - 130px - 10px);
`;
