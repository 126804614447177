import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons/faCheckCircle';
import React, { FunctionComponent, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { useTheme } from 'styled-components';
import Accordion from '../animation/Accordion';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { Container, IconContainer, NotificationText } from './MiniIconNotification.style';

interface Props {
  text?: string;
  setNotificationText: (text: string | undefined) => void;
  loading?: boolean;
  loadingText?: string;
}

const MiniIconNotification: FunctionComponent<Props> = ({ text, setNotificationText, loading, loadingText }) => {
  const theme = useTheme();

  const setDelay = async () => {
    const duration = 0.4 * 1000 * 2;
    const delayTime = 1000;
    const time = duration + delayTime;

    const delay = (ms: number) => {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    };

    await delay(time);
    return undefined;
  };

  useEffect(() => {
    let isMounted = true;

    const updateText = async () => setDelay();

    updateText().then((t) => isMounted && setNotificationText(t));

    return () => {
      isMounted = false;
    };
  }, [text]);

  const hasText = !!(text && text.length > 0);

  return (
    <Accordion isOpen={hasText || !!loading}>
      <Container>
        {loading ? (
          <IconContainer data-testid="notification-icon-loading">
            <ClipLoader color={theme.primary} size={16} />
          </IconContainer>
        ) : (
          <IconContainer data-testid="notification-icon">
            <FontAwesomeIcon color={theme.primary} icon={faCheckCircle} />
          </IconContainer>
        )}
        {(hasText || (!!loading && !!(loadingText && loadingText.length > 0))) && (
          <NotificationText data-testid="notification-text">{text || loadingText}</NotificationText>
        )}
      </Container>
    </Accordion>
  );
};

export default MiniIconNotification;
