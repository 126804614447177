/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import { getItem, setItem } from '../services/local.storage';

type LocalStorageReturn<T> = [value: T | null, updateValue: (newValue: T) => void];

function useLocalStorage<T>(key: string, initialState: T): LocalStorageReturn<T> {
  const [value, setValue] = useState<T | null>(initialState);

  const updateValue = (newValue: T) => {
    setItem(key, newValue);
    setValue(newValue);
  };

  useEffect(() => {
    const newValue = getItem<T>(key);

    if (newValue !== null) {
      setValue(getItem<T>(key));
    }
  }, []);

  return [value, updateValue];
}

export default useLocalStorage;
