import { faLockAlt } from '@fortawesome/pro-regular-svg-icons/faLockAlt';
import { Note } from '@propertypal/shared/src/types/notes';
import { Property } from '@propertypal/shared/src/types/property';
import FontAwesomeIcon from '@propertypal/web-ui/src/icons/FontAwesomeIcon';
import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import useProfileImage from '../../../hooks/useProfileImage';
import NoteItem from './NoteItem';
import { NotesInfo, NotesInfoContainer } from './Notes.style';

interface NoteItemsProps {
  property: Property;
  notes?: Note[];
  onNoteUpdate?: () => void;
}

const NoteItems: React.FC<NoteItemsProps> = ({ property, notes, onNoteUpdate }) => {
  const [notificationText, setNotificationText] = useState<string | undefined>();
  const [updating, setUpdating] = useState<boolean>(false);
  const profileImage = useProfileImage();
  const theme = useTheme();
  const selfAuthored = notes && notes.filter((n) => n.selfAuthored);
  const showAddNewNote = !!(selfAuthored && selfAuthored.length === 0) || !notes;

  return (
    <>
      <NotesInfoContainer className="pp-notes-info-text">
        <FontAwesomeIcon icon={faLockAlt} color={theme.textDark} style={{ marginRight: 10 }} />
        <NotesInfo>These notes are private, only you and your pals can see them</NotesInfo>
      </NotesInfoContainer>
      <div>
        {notes &&
          notes?.length > 0 &&
          notes.map((n) => (
            <NoteItem
              key={n.compoundKey}
              note={n}
              notificationText={notificationText}
              setNotificationText={setNotificationText}
              updating={updating}
              setUpdating={setUpdating}
              onNoteUpdate={onNoteUpdate}
            />
          ))}

        {showAddNewNote && (
          <NoteItem
            note={{
              id: property?.id,
              content: '',
              authorAvatar: profileImage || '',
              selfAuthored: true,
              timeModified: '',
              zone: '',
              compoundKey: '',
              firstName: '',
              surname: '',
            }}
            notificationText={notificationText}
            setNotificationText={setNotificationText}
            updating={updating}
            setUpdating={setUpdating}
            testID="add-note-item"
            onNoteUpdate={onNoteUpdate}
          />
        )}
      </div>
    </>
  );
};

export default NoteItems;
