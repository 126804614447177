import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight';
import { ResizeObserver } from '@juggle/resize-observer';
import { config, animated, useSpring } from '@react-spring/web';
import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import FontAwesomeIcon from '../icons/FontAwesomeIcon';
import { HeaderContainer, HeaderText } from './Accordion.style';

interface AccordionProps {
  className?: string;
  onToggle?: () => void;
  header?: React.ReactNode;
  isOpen: boolean;
  hideHeaderOnToggle?: boolean;
  children: ReactNode;
}

interface AccordionHeaderProps {
  active: boolean;
  text: string;
  onClick: () => void;
  testID: string;
}

export const AccordionHeader: FunctionComponent<AccordionHeaderProps> = (props) => {
  const theme = useTheme();

  return (
    <HeaderContainer onClick={props.onClick} data-testid={`accordion-header-${props.testID}`}>
      <HeaderText>{props.text}</HeaderText>

      <FontAwesomeIcon
        color={theme.textDark}
        icon={faChevronRight}
        style={{
          fontSize: 20,
          marginRight: 10,
          transition: '300ms all',
          transform: props.active ? 'rotate(90deg)' : '',
        }}
      />
    </HeaderContainer>
  );
};

const Accordion: FunctionComponent<AccordionProps> = ({
  className,
  onToggle,
  header,
  isOpen,
  children,
  hideHeaderOnToggle,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [contentMaxHeight, setContentMaxHeight] = useState(0);
  const open = contentMaxHeight > 0 && isOpen;

  useEffect(() => {
    if (contentRef.current) {
      const observer = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          setContentMaxHeight(entry.target.getBoundingClientRect().height);
        });
      });

      observer.observe(contentRef.current);

      return () => observer.disconnect();
    }

    return () => {};
  }, [contentRef, isOpen]);

  const headerStyle = useSpring({
    config: { ...config.default },
    from: { opacity: 0, maxHeight: 0 },
    to: {
      opacity: open ? 0 : 1,
      maxHeight: open ? 0 : 150,
    },
  });

  const bodyStyle = useSpring({
    config: { ...config.default },
    from: { opacity: 0, maxHeight: '0px', overflow: 'hidden' },
    to: {
      opacity: open ? 1 : 0,
      maxHeight: open ? `${contentMaxHeight}px` : '0px',
      overflow: 'visible',
    },
  });

  return (
    <>
      {header && (
        <animated.div
          className={className}
          onClick={onToggle}
          style={hideHeaderOnToggle ? headerStyle : undefined}
          data-testid="accordian-motion-header"
          data-open={isOpen}
        >
          {header}
        </animated.div>
      )}

      {isOpen && (
        <animated.div className={className} style={bodyStyle} data-testid="accordian-motion-section">
          <div ref={contentRef}>{children}</div>
        </animated.div>
      )}
    </>
  );
};

export default Accordion;
