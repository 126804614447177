import { Property } from '../../types';

export interface EnquiryContactDetails {
  email: boolean;
  phone: boolean;
}

const getContact = (property: Property): EnquiryContactDetails => {
  if (property && property.contacts) {
    const { contacts } = property;

    const { default: defaultContact, all } = contacts;

    // handle single agent
    if (defaultContact)
      return {
        email: defaultContact.email,
        phone: defaultContact.phone && !!defaultContact.phoneNumber,
      };

    // handle multi agents
    if (all?.length > 0) {
      return {
        // only show email if at least one agent allows email
        email: all.filter((c) => c.email).length > 0,
        // only show phone if at least one agent allows phone
        phone: all.filter((c) => c.phone && !!c.phoneNumber).length > 0,
      };
    }
  }

  return {
    email: false,
    phone: false,
  };
};

export default getContact;
